<template>
  <section class="create-use-template-wrapper">
    <div id="scene-container-use-template" ref="sceneContainerUseTemplate"></div>
    <!-- <div class="container-fluid inner-template d-flex text-white">
        <div class="w-100">
            <div class="container-use-template ml-auto">
                <div class="mr-5 pr-5">
                    <div class="use-text mr-5">
                        <div class="container-md px-5 py-5 text-left">
                            <h3 class="font-weight-normal">What playing style do you want to use?</h3>
                        </div>
                    </div>
                    <div class="d-flex mt-4 mb-4" style="gap: 16px;">
                        <div @click="chooseType('quiz')" class="card-konten d-flex align-items-center justify-content-center cursor-pointer" :class="quiz == true ? 'quiz-aktif' : 'quiz' ">
                          <div>
                            <img src="@/assets/images/logo/mode-team.svg" alt="" class="mb-2">
                            <h3 class="font-weight-bold">Team</h3>
                          </div>
                        </div>
                        <div @click="chooseType('polling')" class="card-konten d-flex align-items-center justify-content-center cursor-pointer" :class="polling == true ? 'quiz-aktif' : 'quiz' ">
                          <div>
                            <img src="@/assets/images/logo/mode-seru.svg" alt="" class="mb-2">
                            <h3 class="font-weight-bold">Seruu</h3>
                          </div>
                        </div>
                        <div @click="chooseType('training')" class="card-konten d-flex align-items-center justify-content-center cursor-pointer" :class="training == true ? 'quiz-aktif' : 'quiz' ">
                          <div>
                            <img src="@/assets/images/logo/mode-duel.svg" alt="" class="mb-2">
                            <h3 class="font-weight-bold">Duel</h3>
                          </div>
                        </div>
                    </div>
                    <div class="mr-5">
                      <div class="bg-none mt-5 ">
                        <p class="text-center px-3 py-3">{{ copyWriting }}</p>
                      </div>
                      <div v-if="quiz == true || polling == true || training == true" class=" mb-3">
                          <div v-if="quiz == true || training == true" class="card">
                              <div class="card-body">
                                  <div class="d-flex justify-content-between">
                                      <div>
                                          <h3 class="font-weight-normal">Choose the number of teams</h3>
                                      </div>
                                      <div>
                                          <select v-if="quiz == true" name="" v-model="team_number" id="filter" class="form-control mr-3">
                                              <option v-for="(value, index) in number" :key="index" :value="value">{{ value }}</option>
                                          </select>
                                          <select v-else name="" v-model="duel_number" id="filter" class="form-control mr-3">
                                              <option :value="1">1 vs 1</option>
                                              <option :value="2">2 vs 2</option>
                                              <option :value="3">3 vs 3</option>
                                          </select>
                                      </div>
                                  </div>
                                  <p class="mb-0 text-left text-muted">More teams would be more exciting.</p>
                              </div>
                          </div>
                          <div class="card mt-3">
                              <div class="card-body text-left">
                                  <div class="d-flex justify-content-between">
                                      <div>
                                          <h3 class="font-weight-normal">Public</h3>
                                      </div>
                                      <div>
                                        <vs-switch color="success" v-model="formData.public_mode"/>
                                      </div>
                                  </div>
                                  <p class="mb-0 text-left text-muted mt-2">Your content will be viewed and accessed by everyone.</p>
                              </div>
                          </div>
                          <div class="card mt-3">
                            <div class="card-body text-left">
                              <div class="d-flex justify-content-between">
                                <div>
                                  <h3 class="font-weight-normal">Turn on the sound</h3>
                                </div>
                                <div>
                                  <vs-switch color="success" v-model="formData.sound_active"/>
                                </div>
                              </div>
                              <div class="d-flex mt-1">
                                <div class="col-6">
                                  <p class="mb-0 text-left text-muted mt-2">Correct Answer</p>
                                  <div v-if="recordCorrect == false"  class="card mr-5 mt-2 audio-card">
                                    <div class="d-flex align-items-center px-2 py-2">
                                      <button class="btn btn-play d-flex align-items-center justify-content-center" @click="correctAudio"><b-icon :icon="iconFillCorrect"></b-icon></button>
                                      <div class="w-100 ml-2">
                                        <b-progress height="5px" @input="updateTimeCorrect" :min="0" :max="durationCorrect" variant="success" :value="currentTimeCorrect"></b-progress>
                                      </div>
                                      <p class="mb-0 ml-1">{{ timeLabelCorrect }}</p>
                                    </div>
                                  </div>
                                  <div v-else class="card mr-5 mt-2 audio-card d-flex align-items-center justify-content-center position-relative">
                                    <div class="position-absolute cursor-pointer" @click="stopRecordCorrect">
                                        <img src="@/assets/images/icons/mic-red.svg" alt="" class="mic-svg">
                                    </div>
                                      <div class="w-100">
                                        <img src="@/assets/images/icons/audio-effect.svg" alt="" class="img-fluid sound-effect">
                                      </div>
                                  </div>
                                  <div v-if="recordCorrect == false" class="d-flex mt-1 cursor-pointer" style="cursor: pointer;" @click="modalUbahSuara('correct')">
                                    <img src="@/assets/images/icons/ic_pencil_purple.svg" alt="">
                                    <p class="mb-0 ml-1" style="color:#6D75F6">Change Voice</p>
                                  </div>
                                  <div v-else class="d-flex align-items-center mt-1 justify-content-between mr-5">
                                    <p class="text-success mb-0 ml-1 cursor-pointer" @click="stopRecordCorrect" style="cursor: pointer;">Save</p>
                                    <span class="text-muted" style="font-size: 8px;">*Max 5detik</span>
                                  </div>
                                </div>
                                <div class="col-6 text-right">
                                  <p class="mb-0 text-muted mt-2">Incorrect Answer</p>
                                  <div v-if="recordIncorrect == false" class="card ml-5 mt-2 audio-card">
                                    <div class="d-flex align-items-center px-2 py-2">
                                      <button class="btn btn-play d-flex align-items-center justify-content-center" @click="inCorrectAudio"><b-icon :icon="iconFillInCorrect"></b-icon></button>
                                      <div class="w-100 ml-2">
                                        <b-progress height="5px" @input="updateTimeInCorrect" :min="0" :max="durationInCorrect" variant="success" :value="currentTimeInCorrect"></b-progress>
                                      </div>
                                      <p class="mb-0 ml-1">{{ timeLabelInCorrect }}</p>
                                    </div>
                                  </div>
                                  <div v-else class="card ml-5 mt-2 audio-card d-flex align-items-center justify-content-center position-relative">
                                      <div class="position-absolute cursor-pointer" @click="stopRecordIncorrect">
                                          <img src="@/assets/images/icons/mic-red.svg" alt="" class="mic-svg">
                                      </div>
                                      <div class="w-100">
                                        <img src="@/assets/images/icons/audio-effect.svg" alt="" class="img-fluid sound-effect">
                                      </div>
                                  </div>
                                  <div v-if="recordIncorrect == false" class="d-flex mt-1 ml-5 cursor-pointer" style="cursor: pointer;" @click="modalUbahSuara('incorrect')">
                                    <img src="@/assets/images/icons/ic_pencil_purple.svg" alt="">
                                    <p class="mb-0 ml-1" style="color:#6D75F6">Change Voice</p>
                                  </div>
                                  <div v-else class="d-flex align-items-center mt-1 justify-content-between ml-5">
                                    <p class="text-success mb-0 ml-1 cursor-pointer" @click="stopRecordIncorrect" style="cursor: pointer;">Save</p>
                                    <span class="text-muted" style="font-size: 8px;">*Max 5detik</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="mb-5 d-flex align-items-center justify-content-center">
                          <h5 class="mb-0 mr-2 text-purple cursor-pointer" @click="$router.push({ name: 'My-Quiz' })">My Collections</h5>
                          <button v-if="quiz != false || polling != false || training != false" class="btn btn-primary btn-purple" @click="goToLive"><h5 class="mx-2 my-1">Next</h5></button>
                          <button v-else class="btn btn-primary btn-purple disabled"><h5>Next</h5></button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-fluid d-flex justify-content-end text-white">
      <div class="container__game-mode">
        <div class="game-mode__bubble">
          <h3 class="font-weight-normal text-white">What playing style do you want to use?</h3>
        </div>
        <div class="d-flex justify-content-between mt-4 mb-4">
            <div @click="chooseType('quiz')" class="card-konten d-flex align-items-center justify-content-center cursor-pointer" :class="quiz == true ? 'quiz-aktif' : 'quiz' ">
              <div>
                <img src="@/assets/images/logo/mode-team.svg" alt="" class="mb-2">
                <h3 class="font-weight-bold">Team</h3>
              </div>
            </div>
            <div @click="chooseType('polling')" class="card-konten d-flex align-items-center justify-content-center cursor-pointer" :class="polling == true ? 'quiz-aktif' : 'quiz' ">
              <div>
                <img src="@/assets/images/logo/mode-seru.svg" alt="" class="mb-2">
                <h3 class="font-weight-bold">Seruu</h3>
              </div>
            </div>
            <div @click="chooseType('training')" class="card-konten d-flex align-items-center justify-content-center cursor-pointer" :class="training == true ? 'quiz-aktif' : 'quiz' ">
              <div>
                <img src="@/assets/images/logo/mode-duel.svg" alt="" class="mb-2">
                <h3 class="font-weight-bold">Duel</h3>
              </div>
            </div>
        </div>
        <div class="">
          <div class="bg-none mt-5 ">
            <p class="text-center px-3 py-3">{{ copyWriting }}</p>
          </div>
          <div v-if="quiz == true || polling == true || training == true" class=" mb-3">
              <div v-if="quiz == true || training == true" class="card">
                  <div class="card-body">
                      <div class="d-flex justify-content-between">
                          <div>
                              <h3 class="font-weight-normal">Choose the number of teams</h3>
                          </div>
                          <div>
                              <select v-if="quiz == true" name="" v-model="team_number" id="filter" class="form-control mr-3">
                                  <option v-for="(value, index) in number" :key="index" :value="value">{{ value }}</option>
                              </select>
                              <select v-else name="" v-model="duel_number" id="filter" class="form-control mr-3">
                                  <option :value="1">1 vs 1</option>
                                  <option :value="2">2 vs 2</option>
                                  <option :value="3">3 vs 3</option>
                              </select>
                          </div>
                      </div>
                      <p class="mb-0 text-left text-muted">More teams would be more exciting.</p>
                  </div>
              </div>
              <div class="card mt-3">
                  <div class="card-body text-left">
                      <div class="d-flex justify-content-between">
                          <div>
                              <h3 class="font-weight-normal">Public</h3>
                          </div>
                          <div>
                            <vs-switch color="success" v-model="formData.public_mode"/>
                          </div>
                      </div>
                      <p class="mb-0 text-left text-muted mt-2">Your content will be viewed and accessed by everyone.</p>
                  </div>
              </div>
              <div class="card mt-3">
                <div class="card-body text-left">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h3 class="font-weight-normal">Turn on the sound</h3>
                    </div>
                    <div>
                      <vs-switch color="success" v-model="formData.sound_active"/>
                    </div>
                  </div>
                  <div class="d-flex mt-1">
                    <div class="col-6">
                      <p class="mb-0 text-left text-muted mt-2">Correct Answer</p>
                      <div v-if="recordCorrect == false"  class="card mr-5 mt-2 audio-card">
                        <div class="d-flex align-items-center px-2 py-2">
                          <button class="btn btn-play d-flex align-items-center justify-content-center" @click="correctAudio"><b-icon :icon="iconFillCorrect"></b-icon></button>
                          <div class="w-100 ml-2">
                            <b-progress height="5px" @input="updateTimeCorrect" :min="0" :max="durationCorrect" variant="success" :value="currentTimeCorrect"></b-progress>
                          </div>
                          <p class="mb-0 ml-1">{{ timeLabelCorrect }}</p>
                        </div>
                      </div>
                      <div v-else class="card mr-5 mt-2 audio-card d-flex align-items-center justify-content-center position-relative">
                        <div class="position-absolute cursor-pointer" @click="stopRecordCorrect">
                            <img src="@/assets/images/icons/mic-red.svg" alt="" class="mic-svg">
                        </div>
                          <div class="w-100">
                            <img src="@/assets/images/icons/audio-effect.svg" alt="" class="img-fluid sound-effect">
                          </div>
                      </div>
                      <div v-if="recordCorrect == false" class="d-flex mt-1 cursor-pointer" style="cursor: pointer;" @click="modalUbahSuara('correct')">
                        <img src="@/assets/images/icons/ic_pencil_purple.svg" alt="">
                        <p class="mb-0 ml-1" style="color:#6D75F6">Change Voice</p>
                      </div>
                      <div v-else class="d-flex align-items-center mt-1 justify-content-between mr-5">
                        <p class="text-success mb-0 ml-1 cursor-pointer" @click="stopRecordCorrect" style="cursor: pointer;">Save</p>
                        <span class="text-muted" style="font-size: 8px;">*Max 5detik</span>
                      </div>
                    </div>
                    <div class="col-6 text-right">
                      <p class="mb-0 text-muted mt-2">Incorrect Answer</p>
                      <div v-if="recordIncorrect == false" class="card ml-5 mt-2 audio-card">
                        <div class="d-flex align-items-center px-2 py-2">
                          <button class="btn btn-play d-flex align-items-center justify-content-center" @click="inCorrectAudio"><b-icon :icon="iconFillInCorrect"></b-icon></button>
                          <div class="w-100 ml-2">
                            <b-progress height="5px" @input="updateTimeInCorrect" :min="0" :max="durationInCorrect" variant="success" :value="currentTimeInCorrect"></b-progress>
                          </div>
                          <p class="mb-0 ml-1">{{ timeLabelInCorrect }}</p>
                        </div>
                      </div>
                      <div v-else class="card ml-5 mt-2 audio-card d-flex align-items-center justify-content-center position-relative">
                          <div class="position-absolute cursor-pointer" @click="stopRecordIncorrect">
                              <img src="@/assets/images/icons/mic-red.svg" alt="" class="mic-svg">
                          </div>
                          <div class="w-100">
                            <img src="@/assets/images/icons/audio-effect.svg" alt="" class="img-fluid sound-effect">
                          </div>
                      </div>
                      <div v-if="recordIncorrect == false" class="d-flex mt-1 ml-5 cursor-pointer" style="cursor: pointer;" @click="modalUbahSuara('incorrect')">
                        <img src="@/assets/images/icons/ic_pencil_purple.svg" alt="">
                        <p class="mb-0 ml-1" style="color:#6D75F6">Change Voice</p>
                      </div>
                      <div v-else class="d-flex align-items-center mt-1 justify-content-between ml-5">
                        <p class="text-success mb-0 ml-1 cursor-pointer" @click="stopRecordIncorrect" style="cursor: pointer;">Save</p>
                        <span class="text-muted" style="font-size: 8px;">*Max 5detik</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="mb-5 d-flex align-items-center justify-content-center">
              <h5 class="mb-0 mr-2 text-purple cursor-pointer" @click="$router.push({ name: 'My-Quiz' })">My Collections</h5>
              <button v-if="quiz != false || polling != false || training != false" class="btn btn-primary btn-purple" @click="goToLive"><h5 class="mx-2 my-1">Next</h5></button>
              <button v-else class="btn btn-primary btn-purple disabled"><h5>Next</h5></button>
          </div>
        </div>
      </div>
    </div>

        <!-- MODAL UBAH SUARA -->
        <b-modal id="change-voice" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="font-weight-bold">Select Sound</h5>
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('change-voice')"><img src="@/assets/images/icons/ic_close.svg" alt="close" class="img-fluid"></button>
            </div>
            <div class="text-left mb-3">
                <p class="mb-3">for {{changeVoiceType}} answer</p>
                <div v-for="(data, index) in defaultSound" :key="index">
                  <div v-if="changeVoiceType == 'correct'" class="form-check d-flex align-items-center my-2">
                    <input class="form-check-input" v-model="sound_true" type="radio" name="exampleRadios" :id="'default' + index " :value="index+1" checked>
                    <label @click="playPreview(index+1)" class="form-check-label ml-2" :for="'default' + index ">
                      <h5 class="font-weight-normal">{{data.title}}</h5>
                    </label>
                  </div>
                  <div v-if="changeVoiceType == 'incorrect'" class="form-check d-flex align-items-center my-2">
                    <input class="form-check-input" v-model="sound_false" type="radio" name="exampleRadios" :id="'default' + index " :value="index+1" checked>
                    <label @click="playPreview(index+1)" class="form-check-label ml-2" :for="'default' + index ">
                      <h5 class="font-weight-normal">{{data.title}}</h5>
                    </label>
                  </div>
                  <hr style="background: #2D2D2D;">
                </div>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <button class="btn btn-secondary text-white mr-2" @click="cancelDefaultSound()"><p class="mb-0">Cancel</p></button>
              <button class="btn btn-primary btn-purple btn-purple-nb" @click="useDefaultSound()"><p class="mx-2 mb-0">Use</p></button>
            </div>
        </b-modal>
        <Soca v-if="loading"/>
  </section>
</template>
<script>
import * as THREE from "three";
import Soca from '@/components/loading/Soca'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Stats from "stats.js";
import {getQuizById} from '@/services/quiz/quiz.service'
import { postQuizEvents } from '@/services/quiz-events/quiz-events.service'
import {getQuizCreatorById} from '@/services/quiz-creator/quiz-creator.service'
import Swal from "sweetalert2";
import { postQuizTeam } from '@/services/quiz-events/quiz-events.service'
import { postQuizDuel } from '@/services/quiz-events/quiz-events.service'

// import { addQuestions } from '@/services/question/question.service'
// import {addQuizCreator} from '@/services/quiz-creator/quiz-creator.service'

import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import $ from "jquery";

export default {
  components: {
    Soca
  },

  data() {
    return {
      container: null,
      scene: null,
      camera: null,
      controls: null,
      renderer: null,
      stats: null,
      mixers: [],
      clock: null,
      additional : false,
      value : 75,
      max:100,
      listQuestion:[],
      quiz : false,
      polling : true,
      training : false,
      publik : true,
      jadwalkan : false,
      loading: false,
      tanggalMulai : null,
      tanggalSelesai : null,
      copyWriting : 'Participants answer at their own pace, competing individually. (No login required)',
      number : [],
      dataQuiz : [],
      fromCreator : null,
      audioCorrect: null,
      durationCorrect: 0,
      currentTimeCorrect: 0,
      timeLabelCorrect: '00:00',
      iconFillCorrect : 'play-fill',
      audioInCorrect: null,
      durationInCorrect: 0,
      currentTimeInCorrect: 0,
      timeLabelInCorrect: '00:00',
      iconFillInCorrect : 'play-fill',
      previewSoundCorrect: null,
      previewSoundWrong: null,
      team_number : 2,
      duel_number : 1,
      sound_true : 1,
      sound_false : 1,
      formData : {
        is_template : null,
        quiz_id : null,
        quiz_name : null,
        event_mode : null,
        login_mode : null,
        public_mode : false,
        start_event_date : null,
        end_event_date : null,
        play_mode : null,
        sound_active : false,
        sound_true : 1,
        sound_false : 1,
      },
      formTeam : {
        team_number : null
      },
      formDuel : {
        duel_number : null
      },
      recorder: null,
      chunks: [],
      device: null,
      blobObj: null,
      recordCorrect: false,
      timerCorrect : null,

      recorderX: null,
      chunksX: [],
      deviceX: null,
      blobObjX: null,
      recordIncorrect: false,
      timerIncorrect : null,
      changeVoiceType : 'correct',
      defaultSound : [
        {
          title : 'Default 1'
        },
        {
          title : 'Default 2'
        },
        {
          title : 'Default 3'
        },
      ]

    };
  },
  created() {
    // this.device = navigator.mediaDevices.getUserMedia({ audio: true });
    // this.deviceX = navigator.mediaDevices.getUserMedia({ audio: true });
  },
  methods: {
    init() {
      // set container
      this.container = this.$refs.sceneContainerUseTemplate;

      // add stats
      this.stats = new Stats();
      this.clock = new THREE.Clock();
      this.container.appendChild(this.stats.dom);

      // add camera
      const fov = 5.5; // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 1000; // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 2, 12);
      this.camera = camera;

      // create scene
      this.scene = new THREE.Scene();

      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        1 // intensity
      );
      const mainLight = new THREE.DirectionalLight(0xffffff, 4.0);
      mainLight.position.set(10, 10, 10);
      this.scene.add(ambientLight, mainLight);

      // add controls
      this.controls = new OrbitControls(this.camera, this.container);
      // this.controls.enabled = false
      this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI * 0.45;
      this.controls.enableZoom = false; //To enable zoom mode on 3d

      // create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect =
        this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );

      //load model

      const loader = new GLTFLoader();

      const onLoad = (result, position) => {
        const model = result.scene.children[0];
        model.position.copy(position);
        model.scale.set(0.5, 0.5, 0.5);

        const mixer = new THREE.AnimationMixer(model);
        this.mixers.push(mixer);

        const animation = result.animations[0];
        const action = mixer.clipAction(animation);
        action.play();

        this.scene.add(model);
      };

      const parrotPosition = new THREE.Vector3(0, -0.5, 0);
      loader.load(
        "/three-assets/scene.glb",
        (gltf) => onLoad(gltf, parrotPosition),
        undefined,
        undefined
      );

      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    },
    update() {
      const delta = this.clock.getDelta();
      this.mixers.forEach((mixer) => mixer.update(delta));
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },
    chooseType(type){
      if(type == 'quiz'){
        this.quiz = true;
        this.polling = false;
        this.training = false;
        this.copyWriting = "Participants answer at their own pace, but scores are grouped by team. (Requires login)"
      } else if(type == 'polling'){
        this.quiz = false;
        this.polling = true;
        this.training = false;
        this.copyWriting = "Participants answer at their own pace, competing individually. (No login required)"
      } else {
        this.quiz = false;
        this.polling = false;
        this.training = true;
        this.copyWriting = "Participants will compete seriously to achieve maximum results. (Login required)"
      }
    },
    alertForQuiz(){
      Swal.fire('Mohon Maaf', 'Untuk saat ini tipe quiz tersebut belum tersedia')
    },
    addQuiz(){
      localStorage.removeItem("listQuestionCreator");
      localStorage.removeItem("newListQuestionCreator")
      this.$router.push({ name: 'Edit-My-Quiz'})
    },
    goToLive(){
        localStorage.removeItem("sisaWaktu");
        if(this.formData.sound_active == false){
          this.formData.sound_true = 0;
          this.formData.sound_false = 0;
        }

        if(this.quiz == true){ //TEAM
          const creator = this.$route.query.creator

          if(creator == 'true' || creator == true){
            this.formData.is_template = false;
          }else{
            this.formData.is_template = true;
          }

          this.formData.quiz_id = this.dataQuiz._id
          this.formData.quiz_name = this.dataQuiz.quiz_name
          this.formData.login_mode = true
          this.formData.play_mode = "1"
          this.formData.event_mode = "live"

          // Jumlah Team
          this.formTeam.team_number = this.team_number
          postQuizEvents(this.formData)
          .then((response) => {
            if(response.status && response.status == true){
              postQuizTeam(this.formTeam, response.data._id)
              .then((res) => {
                if(res.status && res.status == true){
                  this.$router.push({ name: 'Live-Quiz', query: {key: 'ramean', gc : response.data.quiz_code, id : response.data._id}})
                }
              })
            }
          })
        } else if(this.polling == true){ //SERU SERUAN
          const creator = this.$route.query.creator

          // Validation, is quiz from template or made by it self
          if(creator == 'true' || creator == true){
            this.formData.is_template = false;
          }else{
            this.formData.is_template = true;
          }

          this.formData.quiz_id = this.dataQuiz._id
          this.formData.quiz_name = this.dataQuiz.quiz_name
          this.formData.login_mode = false
          this.formData.play_mode = "2"

          postQuizEvents(this.formData)
          .then((response) => {
            if(response.status && response.status == true){
              this.$router.push({ name: 'Live-Quiz', query: {key: 'seru-seruan', gc : response.data.quiz_code, id : response.data._id}})
            }
          })
        } else { // DUEL
          const creator = this.$route.query.creator

          if(creator == 'true' || creator == true){
            this.formData.is_template = false;
          }else{
            this.formData.is_template = true;
          }

          this.formData.quiz_id = this.dataQuiz._id
          this.formData.quiz_name = this.dataQuiz.quiz_name
          this.formData.login_mode = true
          this.formData.play_mode = "3"
          this.formData.event_mode = "live"

          // Duel number
          this.formDuel.duel_number = this.duel_number
          postQuizEvents(this.formData)
          .then((response) => {
            if(response.status && response.status == true){
              postQuizDuel(this.formDuel, response.data._id)
              .then((res) => {
                if(res.status && res.status == true){
                  this.$router.push({ name: 'Live-Quiz', query: {key: 'duel', gc : response.data.quiz_code, id : response.data._id}})
                }
              })
            }
          })
        }
    },

    getDetailQuiz(id, fromCreator){
      // const creator = this.$route.query.creator
      console.log(fromCreator)
      // Validation, is quiz from template or not
      if(fromCreator == true || fromCreator == 'true'){
        getQuizCreatorById(id)
        .then(response => {
          if(response.status && response.status == true){
              this.dataQuiz = response.data[0]
              this.loading = false
          } else {
            this.loading = false
          }
        }).catch(error => {
          this.loading = false
          console.log(error)
        })
      } else {
        getQuizById(id)
        .then(response => {
            if(response.status && response.status == true){
                this.dataQuiz = response.data[0]
                this.loading = false
            } else {
              this.loading = false
            }
        }).catch(error => {
          this.loading = false
             console.log(error)
        })
      }
    },

    forGetDetail(id, fromCreator){
      this.getDetailQuiz(id, fromCreator)
    },

    correctAudio(){
      if(this.audioCorrect.paused){
        this.audioCorrect.play();
        this.durationCorrect = this.audioCorrect.duration;
        this.iconFillCorrect = 'pause-fill'
      } else {
        this.iconFillCorrect = 'play-fill'
        this.audioCorrect.pause()
      }
    },

    updateTimeCorrect(){
      this.player.currentTime = this.currentTimeCorrect;
    },

    timeUpdate(){
      this.currentTimeCorrect = this.audioCorrect.currentTime
      const hr = Math.floor(this.currentTimeCorrect / 3600);
      const min = Math.floor((this.currentTimeCorrect - (hr * 3600)) / 60);
      const sec = Math.floor(this.currentTimeCorrect - (hr * 3600) - (min * 60));
      this.timeLabelCorrect = `${min.toString()
        .padStart(2, '0')}:${sec.toString()
        .padStart(2, '0')}`;

      if(this.currentTimeCorrect == this.audioCorrect.duration){
        this.iconFillCorrect = "play-fill"
      }
    },

    inCorrectAudio(){
      if(this.audioInCorrect.paused){
        this.audioInCorrect.play();
        this.durationInCorrect = this.audioInCorrect.duration;
        this.iconFillInCorrect = 'pause-fill'
      } else {
        this.iconFillInCorrect = 'play-fill'
        this.audioInCorrect.pause()
      }
    },

    updateTimeInCorrect(){
      this.player.currentTime = this.currentTimeInCorrect;
    },

    timeUpdateInCorrect(){
      this.currentTimeInCorrect = this.audioInCorrect.currentTime
      const hr = Math.floor(this.currentTimeInCorrect / 3600);
      const min = Math.floor((this.currentTimeInCorrect - (hr * 3600)) / 60);
      const sec = Math.floor(this.currentTimeInCorrect - (hr * 3600) - (min * 60));
      this.timeLabelInCorrect = `${min.toString()
        .padStart(2, '0')}:${sec.toString()
        .padStart(2, '0')}`;

      if(this.currentTimeInCorrect == this.audioInCorrect.duration){
        this.iconFillInCorrect = "play-fill"
      }
    },

    useDefaultSound(){
      if(this.sound_true == 1){
        this.audioCorrect = new Audio(require('@/assets/audio/correct_1.mp3'))
        this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
      } else if(this.sound_true == 2){
        this.audioCorrect = new Audio(require('@/assets/audio/correct_2.mp3'))
        this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
      } else {
        this.audioCorrect = new Audio(require('@/assets/audio/correct_3.mp3'))
        this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
      }

      if(this.sound_false == 1){
        this.audioInCorrect = new Audio(require('@/assets/audio/wrong_1.mp3'))
        this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
      } else if(this.sound_false == 2){
        this.audioInCorrect = new Audio(require('@/assets/audio/wrong_2.mp3'))
        this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
      } else {
        this.audioInCorrect = new Audio(require('@/assets/audio/wrong_3.wav'))
        this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
      }

      this.formData.sound_true = this.sound_true
      this.formData.sound_false = this.sound_false
      this.$bvModal.hide('change-voice')
    },

    cancelDefaultSound(){
      this.sound_true = this.formData.sound_true
      this.sound_false = this.formData.sound_false

      if(this.sound_true == 1){
        this.audioCorrect = new Audio(require('@/assets/audio/correct_1.mp3'))
        this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
      } else if(this.sound_true == 2){
        this.audioCorrect = new Audio(require('@/assets/audio/correct_2.mp3'))
        this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
      } else {
        this.audioCorrect = new Audio(require('@/assets/audio/correct_3.mp3'))
        this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
      }

      if(this.sound_false == 1){
        this.audioInCorrect = new Audio(require('@/assets/audio/wrong_1.mp3'))
        this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
      } else if(this.sound_false == 2){
        this.audioInCorrect = new Audio(require('@/assets/audio/wrong_2.mp3'))
        this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
      } else {
        this.audioInCorrect = new Audio(require('@/assets/audio/wrong_3.wav'))
        this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
      }

      this.$bvModal.hide('change-voice')
    },

    // recordAudioCorrect() {
    //   this.recordCorrect = true
    //   this.device.then((stream) => {
    //     this.recorder = new MediaRecorder(stream);
    //     this.recorder.ondataavailable = (e) => {
    //       this.chunks.push(e.data);
    //       console.log(this.chunks)
    //       if (this.recorder.state === "inactive") {
    //         let blob = new Blob(this.chunks, { type: "audio/webm;codecs=opus" });

    //         this.audioCorrect = new Audio(window.URL.createObjectURL(blob))
    //         this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
    //         this.chunks = [];
    //       }
    //     };
    //     // start
    //     this.recorder.start();

    //     this.timerCorrect = setTimeout(() => {
    //         this.stopRecordCorrect()
    //     }, 6000);
    //   });
    // },
    // stopRecordCorrect() {
    //   clearTimeout(this.timerCorrect);
    //   // stop
    //   this.recordCorrect = false
    //   this.recorder.stop();
    // },

    // recordAudioIncorrect() {
    //   this.recordIncorrect = true
    //   this.deviceX.then((stream) => {
    //     this.recorderX = new MediaRecorder(stream);
    //     this.recorderX.ondataavailable = (e) => {
    //       this.chunksX.push(e.data);
    //       console.log(this.chunksX)
    //       if (this.recorderX.state === "inactive") {
    //         let blob = new Blob(this.chunksX, { type: "audio/webm;codecs=opus" });

    //         this.audioInCorrect = new Audio(window.URL.createObjectURL(blob))
    //         this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
    //         this.chunksX = [];
    //       }
    //     };
    //     // start
    //     this.recorderX.start();
    //     this.timerIncorrect = setTimeout(() => {
    //         this.stopRecordIncorrect()
    //     }, 6000);
    //   });
    // },
    // stopRecordIncorrect() {
    //   console.log("STOP REC");
    //   clearTimeout(this.timerIncorrect);
    //   // stop
    //   this.recordIncorrect = false
    //   this.recorderX.stop();
    // },

    modalUbahSuara(value){
      this.changeVoiceType = value
      this.$bvModal.show('change-voice')
    },

    playPreview(index){
      if(this.changeVoiceType == 'correct') {
        if(index == 1){
          this.previewSoundCorrect = new Audio(require('@/assets/audio/correct_1.mp3'))
          this.previewSoundCorrect.play()
        } else if(index == 2){
          this.previewSoundCorrect = new Audio(require('@/assets/audio/correct_2.mp3'))
          this.previewSoundCorrect.play()
        } else {
          this.previewSoundCorrect = new Audio(require('@/assets/audio/correct_3.mp3'))
          this.previewSoundCorrect.play()
        }
      } else {
        if(index == 1){
          this.previewSoundWrong = new Audio(require('@/assets/audio/wrong_1.mp3'))
          this.previewSoundWrong.play()
        } else if(index == 2){
          this.previewSoundWrong = new Audio(require('@/assets/audio/wrong_2.mp3'))
          this.previewSoundWrong.play()
        } else {
          this.previewSoundWrong = new Audio(require('@/assets/audio/wrong_3.wav'))
          this.previewSoundWrong.play()
        }
      }
    }

  },

  computed: {
    hasAdditional(){
        return this.opsi1.length >0;
    }
  },

  mounted() {
    $("section.create-use-template-wrapper").parent().addClass("m-0");
    this.setSideBar();
    this.init();
    this.loading = true

    const id = this.$route.query.key
    const creator = this.$route.query.creator
    this.forGetDetail(id, creator)

    let initNumber = 1;
    for(let index = 1; index < 4; index++) {
        initNumber++;
        this.number.push(initNumber);
    }

    this.audioCorrect = new Audio(require('@/assets/audio/correct_1.mp3'))
    this.audioCorrect.addEventListener('timeupdate', this.timeUpdate, false);
    this.audioInCorrect = new Audio(require('@/assets/audio/wrong_1.mp3'))
    this.audioInCorrect.addEventListener('timeupdate', this.timeUpdateInCorrect, false);
  },
};
</script>

<style scoped>
.inner-template{
  background-color: black;
}

.btn-play{
  background-color: #EDEDED;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.form-control{
  background-color: #636363;
  border:0;
  color: white;
}

.dot-quiz{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #BC3BF2;
}

.progress {
  background-color: #1F1F1F;
}

.dot-polling{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #FDBA59;
}

.dot-training{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #EB5757;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

.text-purple {
  color: #6D75F6;
}

.card-konten{
  height: 30vh;
  width: 12vw;
  background-color: #1F1F1F;
  border-radius: 30px;
}

.quiz:hover{
  border: 3px solid #7280FF;
  /* background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,219,255,1) 100%);
  transform: scale(1.1); */
}

.polling:hover{
  border: 3px solid #FDBA59;
  transform: scale(1.1);
}

.training:hover{
  border: 3px solid #EB5757;
  transform: scale(1.1);
}

.quiz-aktif{
  border: 3px solid #7280FF;
  /* background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,219,255,1) 100%);
  transform: scale(1.1); */
}

.polling-aktif{
  transform: scale(1.1);
  border: 3px solid #FDBA59;
}

.training-aktif{
  transform: scale(1.1);
  border: 3px solid #EB5757;
}

.bg-none{
  background-color:#1F1F1F;
  border-radius:6px;
}

.audio-card{
  height : 3.2rem;
  background-color: #2D2D2D;
}

.sound-effect{
  width : 14rem
}

.mic-svg{
  height: 30px;
  width : 30px;
}
</style>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.create-use-template-wrapper {
  height: 100%;
  width: 100%;
  background-size: cover;
}
.container-use-template{
    padding-top:20vh ;
    left: 30vw;
    width: 50%;
    margin-right: 35rem;
    z-index: 3;
    position: relative;
}
#scene-container-use-template {
  width: 100%;
  height: 100.8%;
  position: fixed;
  left: -20vw;
  top: 4vh;
}
#scene-container-use-template div {
  display: none !important;
}
.container-filter {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}
.container-question {
  width: 67.5%;
}

.container__game-mode {
  padding-top: 20vh;
  position: relative;
  width: 50vw;
  /* left: 30px; */
  z-index: 3;
  padding-right: 10rem;
}

.use-text, .game-mode__bubble{
    position: relative;
    background: #1F1F1F;
    border-radius: 20px;
}

.game-mode__bubble {
  padding: 3rem
}

.use-text:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -42px;
    top: 17px;
    /* border: 10px solid transparent;
        border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
    /* border-right: 32px solid rgba(193,193,193,0.5);  */
    z-index: 2;
}

.game-mode__bubble:before {
  content: "";
    position: absolute;
    top: 50%;
    left: -50px;
    border: 30px solid transparent;
    border-left-color: #1F1F1F;
    transform: translateY(-50%) rotate(180deg);
}

.use-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -44px;
  top: 16px;
  border: 15px solid transparent;
  border-right: 30px solid #1F1F1F;
  z-index: 3;
}

#change-voice .modal-content{
  background-color: #1F1F1F;
  color:white;
}


@media (max-width: 1366px){
  #scene-container-use-template {
    width: 100%;
    /* height: 150%; */
    position: absolute;
    left: -25vw;
    /* top: 20vh; */
    overflow: hidden !important;
  }

  .use-text{
    background: #1F1F1F;
    border-radius: 20px;
    width: 100%;
  }
}

@media (max-width: 1112px){
  .container-use-template {
    left: 40vw;
  }

  .container__game-mode {
    padding-right: 7rem;
  }
}
</style>
